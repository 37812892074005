import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {InViewportModule} from 'ng-in-viewport';
import {FivefArtifactPreviewBrowserComponent} from './containers/preview-browser/fivef-artifact-preview-browser.component';
import {DvtxLazyPreviewDirective} from './directives/dvtx-lazy-preview.directive';
import {
  LazyPreviewLoadingSkeletonComponent
} from './components/lazy-preview-loading-skeleton/lazy-preview-loading-skeleton.component';
import {
  PreviewBrowserImagelistComponent
} from './components/preview-browser-imagelist/preview-browser-imagelist.component';
import {InstantMessagingModule} from 'app/modules/inbox/modules/instant-messaging/instant-messaging.module';
import {ArtifactDetailsComponent} from './components/artifact-details/artifact-details.component';
import {FivefFileSizePipe} from 'app/lib/fivef-ui/util/fivef-file-size.pipe';
import {FivefCommentsComponent} from 'app/lib/fivef-ui/comment/fivef-comments/fivef-comments.component';
import {FivefLabelsComponent} from 'app/lib/fivef-ui/label/fivef-labels/fivef-labels.component';
import {
  FivefLabelsSelectorComponent
} from 'app/lib/fivef-ui/label/fivef-labels-selector/fivef-labels-selector.component';
import {FivefSignatureSelectionComponent} from 'app/lib/fivef-ui/sign/fivef-signature-selection/fivef-signature-selection.component';
import {FivefPdfViewerComponent} from '../fivef-pdf-viewer/fivef-pdf-viewer.component';
import {TranslateModule} from '@ngx-translate/core';
import {ContentEditableModule} from '../../../fivef-legacy/content-editable/content-editable.module';
import {FivefAvatarModule} from '../../profile/fivef-avatar/fivef-avatar.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {FivefDialogComponent} from '../../common/fivef-dialog/fivef-dialog.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {ArtifactPropertiesModule} from '../../../fivef-legacy/artifact-properties/artifact-properties.module';
import {ExportCommentsModule} from '../../../fivef-legacy/export-comments/export-comments.module';
import {LabelsModule} from '../../../fivef-legacy/labels/labels.module';
import {FivefIconMessageBlockComponent} from '../../util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefInfoBlockComponent} from '../../util/fivef-info-block/fivef-info-block.component';

@NgModule({
  imports: [
    CommonModule,
    InViewportModule,
    LabelsModule,
    FivefInfoBlockComponent,
    InstantMessagingModule,
    ExportCommentsModule,
    ArtifactPropertiesModule,
    FivefFileSizePipe,
    FivefCommentsComponent,
    FivefLabelsComponent,
    FivefLabelsSelectorComponent,
    FivefSignatureSelectionComponent,
    FivefPdfViewerComponent,
    TranslateModule,
    ContentEditableModule,
    FivefAvatarModule,
    MatTooltipModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
    FivefDialogComponent,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatButtonToggleModule,
    MatCardModule,
    FormsModule,
    FivefIconMessageBlockComponent
  ],
  declarations: [
    FivefArtifactPreviewBrowserComponent,
    DvtxLazyPreviewDirective,
    LazyPreviewLoadingSkeletonComponent,
    PreviewBrowserImagelistComponent,
    ArtifactDetailsComponent
  ],
  exports: [
    FivefArtifactPreviewBrowserComponent,
    DvtxLazyPreviewDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PreviewBrowserModule {
}
