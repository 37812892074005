import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {ExportCommentsComponent} from './components/export-comments/export-comments.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {InstantMessagingModule} from 'app/modules/inbox/modules/instant-messaging/instant-messaging.module';
import {FivefAlertComponent} from '../../fivef-ui/util/fivef-alert/fivef-alert.component';
import {FivefIconMessageBlockComponent} from '../../fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefLoadingIndicatorComponent} from '../../fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';

@NgModule({
  declarations: [
    ExportCommentsComponent
  ],
  exports: [
    ExportCommentsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    TranslateModule,
    InstantMessagingModule,
    FivefAlertComponent,
    FivefIconMessageBlockComponent,
    FivefLoadingIndicatorComponent
  ]
})
export class ExportCommentsModule {
}
