import {IResource} from '../../lib/fivef-net/fivef-api-resource/models/resource.interface';
import {
  CommandQueue,
  IFiveFStatus,
  IMatIconSupport,
  IProcess,
  IProcessCommand,
  IProcessSortingBy,
  IProcessStatistics,
  IProcessStatus,
  IUploadProcessConfiguration,
  ProcessProfile,
  ProcessType
} from './process.interface';
import * as _ from 'lodash';
import {ProcessParticipant} from 'app/+store/process-participant/process-participant';
import {Client} from '../client/client';
import {Sorters} from 'app/lib/sorter/sorters';
import {StringUtils} from 'app/lib/string_utils';
import {FileUtils} from 'app/lib/file_utils/functions';
import {CollectorCategory} from '../collector-category/collector-category';
import {DocumentType} from 'app/+store/process-artifact/process-artifact';
import {environment} from '../../../environments/environment';

/**
 * Upload process types to differentiate upload paths.
 */
export type UploadType = 'cac' | 'collector' | 'third_party' | 'third_party_email_attachment' | 'project_room' | 'task' | 'revision' | 'quickshare' | 'signature' | 'accessToken' | 'large_file';

export enum FiveFStatusTitle {
  Draft = 'STATUS.DRAFT',
  Open = 'STATUS.OPEN',
  InProgress = 'STATUS.IN_PROGRESS',
  Closed = 'STATUS.CLOSED'
}

export const FiveFStatusIcon = {
  Draft: 'edit',
  Open: 'radio_button_unchecked',
  InProgress: 'timelapse',
  Closed: 'check_circle'
};

export const FiveFStatusColor = {
  Draft: '7b8490',
  Open: 'ff8800',
  InProgress: '5bc4f1',
  Closed: '00ca5c'
};

export const FiveFStatusesMap: { [id: string]: IFiveFStatus } = {
  Draft: {
    id: FiveFStatusTitle.Draft,
    title: FiveFStatusTitle.Draft,
    icon: FiveFStatusIcon.Draft,
    color: FiveFStatusColor.Draft,
    isSVGIcon: false
  },
  Open: {
    id: FiveFStatusTitle.Open,
    title: FiveFStatusTitle.Open,
    icon: FiveFStatusIcon.Open,
    color: FiveFStatusColor.Open,
    isSVGIcon: false
  },
  InProgress: {
    id: FiveFStatusTitle.InProgress,
    title: FiveFStatusTitle.InProgress,
    icon: FiveFStatusIcon.InProgress,
    color: FiveFStatusColor.InProgress,
    isSVGIcon: false
  },
  Closed: {
    id: FiveFStatusTitle.Closed,
    title: FiveFStatusTitle.Closed,
    icon: FiveFStatusIcon.Closed,
    color: FiveFStatusColor.Closed,
    isSVGIcon: false
  }
};

export const FiveFStatuses: IFiveFStatus[] = [
  FiveFStatusesMap.Draft,
  FiveFStatusesMap.Open,
  FiveFStatusesMap.InProgress,
  FiveFStatusesMap.Closed
];

export const FiveFStatusApiMap = {
  'draft': FiveFStatusesMap.Draft,
  'open': FiveFStatusesMap.Open,
  'in_progress': FiveFStatusesMap.InProgress,
  'closed': FiveFStatusesMap.Closed
}

export const FiveFStatusToApiMap = {
  'STATUS.DRAFT': 'draft',
  'STATUS.OPEN': 'open',
  'STATUS.IN_PROGRESS': 'in_progress',
  'STATUS.CLOSED': 'closed'
}

export class ProcessStatus implements IProcessStatus {
  set code(code) {
    this._code = code;
  }

  get code() {
    return this._code;
  }

  constructor(private _code: string = 'open',
              public message: string = '',
              public infoLevel = '',
              public icon: string = '') {
    switch (_code) {
      case 'closed':
        this.icon = 'lock';
        this.message = 'STATUS.CLOSED';
        this.infoLevel = 'success';
        break;
      case 'draft':
        this.icon = 'edit';
        this.message = 'STATUS.DRAFT';
        this.infoLevel = 'default';
        break;
      case 'open':
      case 'in_progress':
        this.icon = 'timelapse'
        this.message = 'STATUS.IN_PROGRESS';
        this.infoLevel = 'info';
        break;
    }
  }

  isClosed() {
    return this._code === 'closed' || this._code === 'canceled';
  }

  isOpen() {
    return !this.isClosed();
  }

  isDraft() {
    return ['draft', 'initiated'].includes(this._code);
  }
}

export class Process implements IProcess, IMatIconSupport {
  readonly type = ProcessType.Process;

  public status: ProcessStatus;

  parentId: string;
  clientId: string;
  favorite: boolean;
  color: string = '#fff';
  dmsFolderId: string;
  dmsAccountType?: string;
  creatorEmail: string;
  creatorName: string;
  ownerEmail: string;
  ownerName: string;
  orgName: string;
  organizationId: string;
  sharingInfo: string;
  identifier: string;
  profile: ProcessProfile;
  participants: ProcessParticipant[];
  client: Client;
  clientName: string;
  syncDmsFolder: boolean;
  roles: string[] = [];
  startedAt: Date;
  completedAt: Date;
  startsAt: Date;
  endsAt: Date;
  canCreateTask: boolean;
  auditProofUntil: Date;
  isSvgIcon = false;
  icon;
  lastUpdatedByEmail: string;
  lastUpdatedByName: string;
  humanReadableProcessType?: string;
  bigFilesEnabledAt?: Date;
  documentRevisionEnabled?: boolean;
  canBeReopened?: boolean;
  categories?: CollectorCategory[];

  // Assessment period
  year: number;
  month: number;

  // Pagination support
  total: number;
  perPage: number;
  records: number;

  // Processing variables in mass actions.
  failed: number;
  completed: number;

  private _commandQueues = {};

  constructor(public id: string,
              public title: string,
              public subtitle: string,
              public description: string,
              public dueDate: Date,
              public createdAt: Date,
              public processType: string,
              public state: string,
              public updatedAt: Date) {
    // Initialize the command queues under each hook name.
    CommandQueue.defaultQueues().forEach(hookName => {
      this._commandQueues[hookName] = [];
    });
    this._setIcon();
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param command
   * @returns {Process}
   */
  addCommand(hook: CommandQueue.CommandQueueName, command): Process {
    this._commandQueues[hook].push(command);
    return this;
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @param {Array<IProcessCommand>} queue
   */
  setQueue(hook: CommandQueue.CommandQueueName, queue): void {
    this._commandQueues[hook] = queue;
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<IProcessCommand>}
   */
  getQueue(hook: CommandQueue.CommandQueueName): Array<IProcessCommand> {
    return this._commandQueues[hook];
  }

  /**
   * @inheritDoc
   *
   * @param {CommandQueue.CommandQueueName} hook
   * @returns {Array<IProcessCommand>}
   */
  runCommands(hook: CommandQueue.CommandQueueName): Array<IProcessCommand> {
    if (!_.includes(CommandQueue.defaultQueues(), hook)) {
      console.log(`ProcessContext: queue ${hook} is unknown.`);
      return [];
    } else {
      if (this._commandQueues[hook].length === 0) {
        console.log(`ProcessContext: Command queue ${hook} is empty.`);
        return [];
      } else {
        console.log(`ProcessContext: Running commands from queue ${hook}.`);

        let i = 0;
        let command = this._commandQueues[hook][i];
        while (command && command.execute()) {
          i += 1;
          command = this._commandQueues[hook][i];
        }
        return this._commandQueues[hook].slice(i + 1);
      }
    }
  }

  static isQuickshare(process: Process) {
    if (process && process.processType) {
      return process.processType === 'quickshare_v2';
    }
    return false;
  }

  static humanProcessType(process: { processType: string; profile: ProcessProfile }, withProcessProfile = false) {
    if (!process) return '';
    return withProcessProfile
      ? Process.humanProcessTypeFromString(process.processType, process.profile)
      : Process.humanProcessTypeFromString(process.processType);
  }

  static humanProcessTypeFromString(processType, processProfile: ProcessProfile = null): string {
    switch (processType) {
      case 'audit_annual_auditings':
        return 'WORKFLOWS.ANNUAL_AUDIT_TITLE';
      case 'auftragsbestaetigung':
        return 'Auftragsbestätigung';
      case 'jahresabschlusspruefung-pbc-list':
        return 'Prepared by client';
      case 'fastdocs_employee_master_data':
        return 'FASTDOCS.EMPLOYEE_MASTER_DATA.TITLE';
      case 'audit_group_auditings':
        return 'WORKFLOWS.GROUP_AUDIT_TITLE';
      case 'cav':
      case 'audit_contact_imports':
        if (processProfile === ProcessProfile.StandardProfile) {
          return 'PC.PCCV_TITLE';
        }
        return 'WORKFLOWS.GA_CONTACT_VERIFICATION.WORKFLOW_TITLE';
      case 'cac':
      case 'audit_component_auditor_communications':
        if (processProfile === ProcessProfile.StandardProfile) {
          return 'PC.PC_TITLE';
        }
        return 'WORKFLOWS.GA_CA_COMMUNICATION.WORKFLOW_TITLE';
      case 'pbc-delegate':
        return 'Prepared by client (Request)';
      case 'quickshare_v2':
        return 'Quickshare';
      case 'project_room':
      case 'quickshare':
      case 'quickstart':
        return 'PROJECT_ROOM.TITLE';
      case 'third_party':
        return 'THIRD_PARTY_REQUEST.THIRD_PARTY_REQUEST';
      case 'leasing':
        return 'THIRD_PARTY.VARIANTS.CONFIRMATION_LEASING';
      case 'bank':
        return 'THIRD_PARTY.VARIANTS.CONFIRMATION_BANK';
      case 'safekeeping':
        return 'THIRD_PARTY.VARIANTS.CONFIRMATION_ASSET';
      case 'expert':
        return 'THIRD_PARTY.VARIANTS.CONFIRMATION_EXPERT';
      case 'insurance':
        return 'Bestätigung Dritter (Versicherung)';
      case 'claim_invoice':
        return 'THIRD_PARTY.VARIANTS.CLAIM_INVOICE';
      case 'claim_balance':
        return 'THIRD_PARTY.VARIANTS.CLAIM_BALANCE';
      case 'liability_balance':
        return 'THIRD_PARTY.VARIANTS.LIABILITY_BALANCE';
      case 'liability_invoice':
        return 'THIRD_PARTY.VARIANTS.LIABILITY_INVOICE';
      case 'third_party_request':
        return 'THIRD_PARTY.VARIANTS.CONFIRMATION_REQUEST';
      case 'audit_contact_verifications':
        return 'Kontakt Verifizierung';
      case 'cac_request':
      case 'audit_component_auditor_communication_contribution_process':
        if (processProfile === ProcessProfile.StandardProfile) {
          return 'PC.PC_TITLE';
        }
        return 'WORKFLOWS.GA_CA_COMMUNICATION.WORKFLOW_TITLE';
      case 'dummy':
        return '';
      case 'collector':
      case 'quickcollector':
        if (processProfile === ProcessProfile.Fibu) {
          return 'Collecto (Bookman FiBu)';
        }
        if (processProfile === ProcessProfile.DatevDuo) {
          return 'Collecto (DATEV FiBu)';
        }
        return 'Collecto';
      case 'project':
        return 'PROJECT.PROCESS_TITLE';
      case 'topic':
        return 'Topic';
      case 'signature':
        return 'SIGNATURE.SIGNATURE';
      case 'fibu':
        return 'FIBU.FIBU_TITLE';
      default:
        return '';
    }
  }

  /**
   * Returns the icon of the corresponding state.
   * @param state
   */
  static iconOfState(state: string) {
    switch (state) {
      case 'draft':
        return 'edit';
      case 'closed':
        return 'lock';
      default:
        return 'timelapse';
    }
  }

  /**
   * Returns the icon of the corresponding state.
   * @param state
   */
  static humanProcessState(state: string) {
    switch (state) {
      case 'draft':
        return 'STATUS.DRAFT';
      case 'closed':
        return 'STATUS.CLOSED';
      default:
        return 'STATUS.IN_PROGRESS';
    }
  }

  // public isCollecto(): boolean {
  //   return this.processType === 'quickcollector';
  // }

  // public isProjectRoom(): boolean {
  //   return this.processType === 'quickstart';
  // }

  // public isProject(): boolean {
  //   return this.processType === 'project';
  // }


  public isThirdPartyPreparation(): boolean {
    const profiles = [
      ProcessProfile.ThirdPartyClaimsBalance,
      ProcessProfile.ThirdPartyClaimsInvoice,
      ProcessProfile.ThirdPartyLiabilityBalance,
      ProcessProfile.ThirdPartyLiabilityInvoice
    ];
    return !!profiles.find(p => p === this.profile);
  }

  public static isThirdParty(process): boolean {
    if (!process) return false;
    return Process.isThirdPartyType(process.processType);
  }

  public static isThirdPartyType(type): boolean {
    if (!type) return false;

    switch (type) {
      case 'third_party':
      case 'leasing':
      case 'bank':
      case 'safekeeping':
      case 'expert':
      case 'insurance':
      case 'claim_invoice':
      case 'claim_balance':
      case 'liability_balance':
      case 'liability_invoice':
        return true;
    }
    return false;
  }

  public static thirdPartyTypes(): string[] {
    return ['third_party', 'leasing', 'bank', 'safekeeping', 'expert', 'insurance', 'claim_invoice', 'claim_balance', 'liability_balance', 'liability_invoice'];
  }

  public isFiBu(): boolean {
    return this.processType === 'fibu';
  }

  private _setIcon() {
    const config = Process.iconForType(this.processType);
    this.icon = config.icon;
    this.isSvgIcon = config.isSvgIcon;
  }

  static iconForType(workflowType): { icon: string, isSvgIcon: boolean } {
    const config = {
      icon: null,
      isSvgIcon: false
    };
    switch (workflowType) {
      case 'templates':
        config.icon = 'templates';
        config.isSvgIcon = true;
        break;
      case 'society':
      case 'organization':
        config.icon = 'domain';
        break;
      case 'attachment':
        config.icon = 'attach_file';
        break;
      case 'tasks':
      case 'task':
      case 'standard_task':
        config.icon = 'tasks';
        config.isSvgIcon = true;
        break;
      case 'invoice_approval':
        config.icon = 'invoice_approval';
        config.isSvgIcon = true;
        break;
      case 'project':
        config.icon = 'process';
        config.isSvgIcon = true;
        break;
      case 'quickshare_v2':
      case 'quickshare':
      case 'share':
        config.icon = 'share';
        break;
      case 'cac':
      case 'exchange_circle':
      case 'cac_request':
      case 'audit_component_auditor_communications':
      case 'audit_component_auditor_communication_contribution_process':
        config.icon = 'exchange_circle';
        config.isSvgIcon = true;
        break;
      case 'collector':
      case 'quickcollector':
      case 'playlist_add_check':
        config.icon = 'playlist_add_check';
        break;
      case 'cav':
      case 'person_verif':
      case 'audit_contact_imports':
      case 'contact_verification':
      case 'audit_contact_verifications':
        config.icon = 'person_verif';
        config.isSvgIcon = true;
        break;
      case 'signature':
        config.icon = 'sign';
        config.isSvgIcon = true;
        break;
      case 'fastdocs_employee_master_data':
        config.icon = 'lohn';
        config.isSvgIcon = true;
        break;
      case 'third_party':
      case 'leasing':
      case 'bank':
      case 'safekeeping':
      case 'expert':
      case 'insurance':
      case 'claim_invoice':
      case 'claim_balance':
      case 'liability_balance':
      case 'liability_invoice':
      case 'third_party_request':
        config.icon = 'third_party';
        config.isSvgIcon = true;
        break
      case 'fibu':
        config.icon = 'fibu_workflow';
        config.isSvgIcon = true;
        break;
      default:
        config.icon = 'projectroom';
        config.isSvgIcon = true;
    }
    return config;
  }

  static isSignature(process) {
    if (!process) return false;
    return process.processType === 'signature';
  }

  static isCollecto(process) {
    if (!process) return false;
    return process.processType === 'quickcollector';
  }

  static isProjectRoom(process) {
    if (!process) return false;
    return process.processType === 'quickstart';
  }

  static isProject(process) {
    if (!process) return false;
    return process.processType === 'project';
  }

  static isCacRequest(process) {
    if (!process) return false;
    return process.processType === 'cac_request' || process.processType === 'cac';
  }

  static isSalary(process) {
    if (!process) return false;
    return process.processType === 'fastdocs_employee_master_data';
  }

  static isBookmanFibu(process) {
    if (!process || !process.profile) return false;
    return process.profile === ProcessProfile.Fibu;
  }

  static isDatevDuo(process) {
    if (!process || !process.profile) return false;
    return process.profile === ProcessProfile.DatevDuo;
  }

  static isOverdue(process) {
    if (!process || !process.dueDate) return false;
    return Process.isOverdueDate(process.dueDate);
  }

  static isOverdueDate(date) {
    if (!date) return false;
    const currentDate = new Date(new Date().setHours(0, 0, 0));
    const dueDate = new Date(new Date(date).setHours(0, 0, 0));
    return (
      Math.round(
        (dueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
      ) <= 0
    );
  }

  static isAboutToBeOverdue(process) {
    if (!process || !process.dueDate) return false;

    return this.overduedIn(process) <= 3
  }

  static isDateAboutToBeOverdue(date) {
    if (!date) return false;

    return this.overduedDateIn(date) <= 3
  }

  static overduedIn(process) {
    if (!process || !process.dueDate) return false;
    return Process.overduedDateIn(process.dueDate);
  }

  static overduedDateIn(date) {
    const currentDate = new Date(new Date().setHours(0, 0, 0));
    const dueDate = new Date(new Date(date).setHours(0, 0, 0));
    return (
      Math.round(
        (dueDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)
      )
    );
  }

  /**
   * Returns upload configurations for given process profiles.
   *
   * @param process
   * @param uploadType
   * @param role
   * @param documentType
   */
  static uploadConfigurationFor(process: Process, uploadType: UploadType, role = 'default', documentType = DocumentType.Document): IUploadProcessConfiguration {
    const config: IUploadProcessConfiguration = {
      supportedFileTypes: FileUtils.DEFAULT_SUPPORTED_FILE_TYPES,
      maxFileSize: FileUtils.DEFAULT_MAX_FILE_SIZE,
      uploadInformation: 'UPLOAD.PLACEHOLDER_UPLOAD_INFORMATION_2',
      uploadInfoTooltip: true,
      enableDmsFilePicker: true
    };

    if (uploadType === 'accessToken') {
      config['enableDmsFilePicker'] = false;
      return config;
    }

    if (uploadType === 'large_file') {
      config['enableDmsFilePicker'] = false;
      config['maxFileSize'] = 20000000;
      return config;
    }

    if (uploadType === 'signature') {
      config['supportedFileTypes'] = [
        'csv', 'pdf', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt',
        'odt', 'odg', 'odp', 'ods'
      ];
      config['maxFileSize'] = 25000;
      config['uploadInfoTooltip'] = true;
      config['enableDmsFilePicker'] = false;

      return config;
    }

    if (process && process.profile === ProcessProfile.Fibu && role !== 'template') {
      config['supportedFileTypes'] = ['pdf'];
      config['maxFileSize'] = 7000;
      config['uploadInformation'] = 'FIBU.PLACEHOLDER_UPLOAD_INFORMATION';
      config['uploadInfoTooltip'] = false;
      config['enableDmsFilePicker'] = false;
    }

    if (process && process.profile === ProcessProfile.DatevDuo && role !== 'template' && (documentType === DocumentType.Document || documentType === DocumentType.OtherReceipt)) {
      config['supportedFileTypes'] = FileUtils.DEFAULT_SUPPORTED_FILE_TYPES;
      config['maxFileSize'] = 7000;
      config['uploadInformation'] = 'UPLOAD.PLACEHOLDER_UPLOAD_INFORMATION_2';
      config['uploadInfoTooltip'] = true;
      config['enableDmsFilePicker'] = false;
    }

    if (process && process.profile === ProcessProfile.DatevDuo && role !== 'template') {
      config['supportedFileTypes'] = ['pdf'];
      config['maxFileSize'] = 7000;
      config['uploadInformation'] = 'FIBU.PLACEHOLDER_UPLOAD_INFORMATION';
      config['uploadInfoTooltip'] = false;
      config['enableDmsFilePicker'] = false;
    }

    return config;
  }

  static uploadUrl(id: string, processType: UploadType, isDraft: boolean, role: string, resourceId: any = null): string {
    switch (processType) {
      case 'cac':
        // Appendix as attachment in draft mode.
        if (isDraft && resourceId) {
          return `/api/v1/audit/cac/processes/${id}/attachments?appendice_id=${resourceId}`;
        }

        // Instruction set as attachment in draft mode or published mode.
        if (isDraft || (role === 'template' && !resourceId)) {
          return `/api/v1/audit/cac/processes/${id}/attachments`;
        }

        // Use standard upload. reference ID is set as POST parameter.
        return `/api/v1/workflow_engine/quickstart/${id}/upload?serializer=v2`;

      case 'third_party':
        if (isDraft) {
          // return `${environment.token_service_config.apiBase}/${environment.token_service_config.apiPath}/workflow_engine/processes/${this._processId}/uploads?reference_id=${referenceId}&process_id=${this._processId}`;
          return `/api/v1/audit/third_party_confirmations/${id}/upload?reference_id=${resourceId}`;
        }
        // Use standard upload. reference ID is set as POST parameter.
        return `/api/v1/workflow_engine/quickstart/${id}/upload?serializer=v2`;

      case 'collector':
        if (isDraft) {
          return `/api/v1/clr/checklists/${id}/nodes/${resourceId}/attachments`;

        } else if (resourceId) {
          return `/api/v1/clr/checklists/${id}/nodes/${resourceId}/uploads`;

        }
        return `/api/v1/workflow_engine/quickstart/${id}/upload?serializer=v2`;

      case 'task':
        return `/api/v1/tasks/tasks/${resourceId}/attachments`;

      case 'revision':
        return `/api/v1/workflow_engine/processes/${id}/artifacts/${resourceId}/versions`;

      case 'quickshare':
        return `/api/v1/quickshare/processes/${id}/attachments`;

      default:
        // case 'project_room':
        // case 'quickshare_v2':
        // case 'quickshare':
        // case 'quickstart':
        // case 'project':
        return `/api/v1/workflow_engine/quickstart/${id}/upload?serializer=v2`;
    }
  }

  public static getWorkflowTranslationMap(): { [slug: string]: string } {
    const workflowTranslationMap: { [slug: string]: string } = {};
    workflowTranslationMap['project'] = Process.humanProcessTypeFromString('project');
    workflowTranslationMap['quickshare_v2'] = Process.humanProcessTypeFromString('quickshare_v2');
    workflowTranslationMap['collector'] = Process.humanProcessTypeFromString('collector');
    workflowTranslationMap['project_room'] = Process.humanProcessTypeFromString('project_room');
    workflowTranslationMap['cac'] = Process.humanProcessTypeFromString('cac');
    workflowTranslationMap['cav'] = Process.humanProcessTypeFromString('cav');
    workflowTranslationMap['third_party'] = Process.humanProcessTypeFromString('third_party');
    workflowTranslationMap['signature'] = Process.humanProcessTypeFromString('signature');
    workflowTranslationMap['fastdocs_employee_master_data'] = Process.humanProcessTypeFromString('fastdocs_employee_master_data');
    return workflowTranslationMap;
  }

  /**
   * Returns the dashboard/overview URL of process with processId and type.
   * @param type
   * @param processId
   */
  public static getProcessOverviewURL(type: string, processId: string) {
    const host = window.location.hostname;
    switch (type) {
      case 'collector':
      case 'quickcollector':
        return `${host}/#/collecto/run/${processId}/dashboard`;
      default:
        throw Error(`Unspecified Dashboard URL for type ${type}`);
    }
  }
}

export class ProcessStatistics implements IProcessStatistics {
  readonly type = 'process_statistics';


  constructor(public id: string,
              public all: number,
              public open: number,
              public closed: number,
              public allTaskCount: number,
              public openTaskCount: number,
              public closedTaskCount: number,
              public documentCount: number) {
  }
}

/**
 * 'No'-opereation. Command for testing purpose.
 * Does nothing.
 */
export class NoopCommand implements IProcessCommand {
  constructor(private _message = 'NoopCommand#execute: No Op.',
              private _continueControlFlow: boolean = false) {
  }

  execute() {
    console.log(this._message);
    return this._continueControlFlow;
  }
}

/**
 * Navigation command. Uses the Angular router, a client route to navigate to a client address.
 * The third parameter of the constructor is used to preserve query parameters.
 *
 * Routing in the client disrupts the control flow (execute() -> false).
 */
export class ClientNavigationCommand implements IProcessCommand {
  /**
   * Creates a new <tt>ClientNavigationCommand</tt>.
   * @param _command Client command.
   * @param _router Angular router.
   */
  constructor(private _command, private _router) {
  }

  /**
   * Route to the given client route. Returns false to disrupt the control flow.
   * @returns {boolean}
   */
  execute() {
    const params = {};
    let preserveQueryParams = true;
    if (this._command.preserve_query_params != null) {
      preserveQueryParams = this._command.preserve_query_params;
    }

    if (preserveQueryParams) {
      params['queryParamsHandling'] = 'preserve';
    }

    if (this._command.replace_url) {
      params['replaceUrl'] = this._command.replace_url;
    }
    this._router.navigate([this._command.client_route], params);
    return false;
  }
}

export const SortByProcessTitleComparer = isAsc => {
  return (l, r) => Sorters.sort(StringUtils.normalizeString(l.title), StringUtils.normalizeString(r.title), isAsc);
};

export const SortByClientNameComparer = () => {
  return (l, r) => Sorters.sort(StringUtils.normalizeString(l.clientName), StringUtils.normalizeString(r.clientName));
};

export const SortByLastUpdatedAtComparer = () => {
  return (l, r) => Sorters.sortByDates(l.updatedAt, r.updatedAt, false);
};

export const ProcessSortingBy: IProcessSortingBy[] = [
  {
    title: 'SORT.BY_LAST_UPDATED_AT',
    comparer: SortByLastUpdatedAtComparer()
  },
  {
    title: 'SORT.BY_TITLE_ASC',
    comparer: SortByProcessTitleComparer(true)
  },
  {
    title: 'SORT.BY_TITLE_DESC',
    comparer: SortByProcessTitleComparer(false)
  },
  {
    title: 'SORT.BY_CLIENT_NAME',
    comparer: SortByClientNameComparer()
  }
];

export class ProcessListingItemV3 implements IResource {
  readonly type = 'workflow_engine_process_listing_item';

  // Pagination support
  total: number;
  perPage: number;
  records: number;

  humanReadableProcessType: string;

  clientName: string;
  clientNo: string;

  ownerName: string;
  ownerEmail: string;

  trackByKey: string;

  year?: number;
  month?: number;

  constructor(public id: string,
              public title: string,
              public description: string,
              public identifier: string,
              public processType: string,
              public status: IFiveFStatus,
              public organizationId: string,
              public clientId: string,
              public dueDate: Date,
              public color: string,
              public creatorName: string,
              public creatorEmail: string,
              public trashedAt: Date,
              public createdAt: Date,
              public updatedAt: Date) {
    this.humanReadableProcessType = Process.humanProcessTypeFromString(this.processType);
    this.trackByKey = `${id}|${updatedAt}`;
  }
}

export class ProcessListingV3Statistics implements IResource {
  readonly type = 'workflow_engine_process_statistics';

  constructor(public id: string,
              public all: number,
              public open: number,
              public closed: number,
              public draft: number) {
  }
}

export class ProcessListingV3Participant implements IResource {
  readonly type = 'workflow_engine_process_participants';
  public id: string;

  constructor(public email: string,
              public firstName: string,
              public lastName: string) {
    this.id = email;
  }
}

export class FlatProcess implements IResource {
  readonly type = 'workflow_engine_processes';

  constructor(public id: string,
              public title: string,
              public color: string,
              public processType: string,
              public createdAt: Date,
              public updatedAt: Date) {
  }
}

/**
 * View type of the settings.
 * Currently supported process view types are by API:
 *
 *   enum identifier: {
 *     overview: 0,
 *     project_room: 1,
 *     artifacts: 2,
 *     comments: 3,
 *     participations: 4
 *   }
 */
export enum ProcessSettingsViewType {
  Overview = 'overview',
  ProjectRoomView = 'project_room',
  ArtifactView = 'artifacts',
  CommentView = 'comments',
  ParticipationView = 'participations'
}

export enum ProcessSettingsDeviceType {
  Web = 'web',
  Print = 'print'
}


/**
 * Persisted view settings model at API.
 * The config is dependent of the options the process provides.
 */
export class ProcessViewSettings implements IResource {
  readonly type = 'process_view_settings';

  constructor(public id: string,
              public config: any,
              public createdAt: Date,
              public updatedAt: Date) {
  }
}

export const WORKFLOWS_TYPES = [
  'project',
  'quickshare_v2',
  'collector',
  'project_room',
  'signature',
  'cac',
  'cav',
  'third_party',
  'fastdocs_employee_master_data',
];

export const WORKFLOWS_TYPES_TITLES = {
  'project': Process.humanProcessTypeFromString('project'),
  'quickshare_v2': Process.humanProcessTypeFromString('quickshare_v2'),
  'collector': Process.humanProcessTypeFromString('collector'),
  'project_room': Process.humanProcessTypeFromString('project_room'),
  'signature': Process.humanProcessTypeFromString('signature'),
  'cac': Process.humanProcessTypeFromString('cac'),
  'cav': Process.humanProcessTypeFromString('cav'),
  'third_party': Process.humanProcessTypeFromString('third_party'),
  'fastdocs_employee_master_data': Process.humanProcessTypeFromString('fastdocs_employee_master_data'),
}
