import {Injectable} from '@angular/core';
import {catchError, concatMap, first, switchMap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  Create,
  CreateFail,
  CreateSuccess,
  Delete,
  DeleteFail,
  DeleteSuccess,
  DmsFolderActionTypes,
  LoadAll,
  LoadAllFail,
  LoadAllSuccess,
  LoadOne,
  LoadOneFail,
  LoadOneSuccess,
  Refresh,
  RefreshFail,
  RefreshSuccess,
  Rename,
  RenameFail,
  RenameSuccess
} from './dms-folder.actions';
import {DmsFolder} from './dms-folder';
import {DmsFolderService} from './dms-folder.service';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class DmsFolderEffects {
  loadAll$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.LoadAll),
    concatMap((action: LoadAll) => {
      return this.svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((res: DmsFolder[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this.notifyService.error('DMS.LOADING_FOLDERS_FAILED')
          return of(new LoadAllFail(err));
        }));
    })
  ));

  loadAllOrganization$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.LoadAllOrganization),
    concatMap((action: LoadAll) => {
      return this.svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((res: DmsFolder[]) => {
          return [new LoadAllSuccess(res)];
        }),
        catchError(err => {
          this.notifyService.error('DMS.LOADING_FOLDERS_FAILED');
          return of(new LoadAllFail(err));
        }));
    })
  ));

  refresh$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.Refresh),
    concatMap((action: Refresh) => {
      return this.svc.getAll(action.dmsAccountType).pipe(
        first(),
        concatMap((folders: DmsFolder[]) => {
          return [new RefreshSuccess(folders)];
        }),
        catchError(err => {
          this.notifyService.error('DMS.LOADING_FOLDERS_FAILED');
          return of(new RefreshFail(err));
        }));
    })
  ));

  loadOne$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.LoadOne),
    switchMap((action: LoadOne) => {
      return this.svc.getOne(action.id, action.accountType).pipe(
        first(),
        concatMap((folder: DmsFolder) => {
          return [new LoadOneSuccess(folder)];
        }),
        catchError(err => {
          this.notifyService.error('DMS.LOADING_FOLDER_FAILED');
          return of(new LoadOneFail(err));
        }));
    })
  ));

  create$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.Create),
    switchMap((action: Create) => {
      console.error('create$', action);
      return this.svc.create(action.name, action.parent, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: DmsFolder) => {
          console.error('create$', folder);
          if (folder.parentId) {
            return [new LoadOne(action.parent, action.dmsAccountType), new CreateSuccess(folder)];
          }
          return [new CreateSuccess(folder)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this.notifyService.error(error.error.errors[0].title);
          }
          return of(new CreateFail(error));
        }));
    })
  ));

  rename$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.Rename),
    switchMap((action: Rename) => {
      return this.svc.rename(action.id, action.name, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: DmsFolder) => {
          this.notifyService.success('DMS.FOLDER_RENAMED_SUCCESS');
          return [new RenameSuccess(folder)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this.notifyService.error(error.error.errors[0].title);
          } else {
            this.notifyService.error('DMS.FOLDER_RENAMED_FAILURE');
          }
          return of(new RenameFail(error));
        }));
    })
  ));

  destroy$ = createEffect(() => this.actions.pipe(
    ofType(DmsFolderActionTypes.Delete),
    switchMap((action: Delete) => {
      return this.svc.delete(action.id, action.dmsAccountType).pipe(
        first(),
        concatMap((folder: DmsFolder) => {
          // Reload the parent. It could now be deletable as well if it has no children anymore.
          return [new DeleteSuccess(folder), new LoadOne(folder.parentId, folder.dmsAccountType)];
        }),
        catchError(error => {
          if (error && error.error && error.error.errors && error.error.errors.length > 0) {
            this.notifyService.error(error.error.errors[0].title);
          }
          return of(new DeleteFail(error));
        }));
    })
  ));

  constructor(private actions: Actions,
              private svc: DmsFolderService,
              private notifyService: FivefNotificationService) {
  }
}




