<div>
  <div class="comment--box d-flex w-100" *ngIf="avatar$">
    <!-- Avatar -->
    <span class="comment--box--performer">
      <fivef-avatar [size]="'sm'" [email]="avatar$" [matTooltip]="avatar$?.name"
                    [showTitle]="false"></fivef-avatar>
    </span>

    <!-- Right aligned content box -->
    <div class="comment--box--content">
      <div class="user-box-content">
        <ul class="box-header box-header-default">
          <!-- Header line: Date/performer -->
          <li class="performer">
            <div class="w-100 d-flex flex-wrap justify-content-between align-items-center">
              <div class="w-50">
                <span
                  class="created-at dvtx-fg-color dvtx-fg-fivef-blue-400">{{ _message.createdAt | date : dateFormat }}
                  <ng-container
                    *ngIf="_message?.createdAt !== _message?.updatedAt"><em> ({{ 'GENERAL.EDITED' | translate }}) </em></ng-container> - <strong>{{ avatar$?.name || avatar$?.email }}</strong>
                </span>
              </div>
              <!-- Comment toolbar & artifact / Collecto element reference -->
              <div class="w-50 d-flex align-items-center justify-content-end">
                <div dvtxFetchCollectorItem
                     [fetchCollectorItemId]="_message?.itemId"
                     [fetchCollectorItemProcessId]="_message?.processId"
                     class="dvtx-reference-bar">
                  <ng-container *ngIf="item$ | async as item">
                    <span [matTooltip]="item?.categoryTitle + ' / ' + item?.itemTitle"
                          matTooltipPosition="left">
                      <mat-icon class="dvtx-collector-reference-icon">playlist_add_check</mat-icon>
                      <span class="dvtx-collector-grouping">
                        {{ ((item?.categoryTitle | translate)?.length > 22) ? (item?.categoryTitle | translate | slice:0:22) : (item?.categoryTitle | translate) }}
                        {{ ((item?.categoryTitle | translate)?.length > 22) ? '...' : '' }}
                      </span>
                      <span class="dvtx-collector-grouping dvtx-reference-bar--collector-item">
                        <a [routerLink]="'/collecto/run/' + item.processId + '/dashboard'"
                           [queryParams]="{tabId: 1, itemId: item.id}">
                           {{ ((item?.itemTitle | translate)?.length > 22) ? (item?.itemTitle | translate | slice:0:22) : (item?.itemTitle | translate) }}
                          {{ ((item?.itemTitle | translate)?.length > 22) ? '...' : '' }}</a>
                      </span>
                    </span>
                  </ng-container>
                </div>

                <!-- Artifact reference -->
                <div *ngIf="_message.type == ProcessEventType.ArtifactComment"
                     class="dvtx-reference-bar"
                     [class.dvtx-active-artifact-link]="process">
                  <span [matTooltip]="_message?.title"
                        matTooltipPosition="left"
                        (click)="openPreviewDialog()">
                    <mat-icon class="dvtx-artifact-reference-icon">insert_drive_file</mat-icon>
                    <span class="dvtx-collector-grouping">
                      {{ ((_message?.title | translate)?.length > 22) ? (_message?.title | translate | slice:0:22) : (_message?.title | translate) }}
                      {{ ((_message?.title | translate)?.length > 22) ? '...' : '' }}
                    </span>
                  </span>
                </div>

                <!-- Top right comment 3-dots menu -->
                <div class="d-flex performer-menu">
                  <ng-template [ngIf]="_message.type === ProcessEventType.InstantMessage">
                    <dvtx-message-panel-info-dropdown [title]="'GENERAL.VISIBILITY' | translate"
                                                      [icon]="'supervised_user_circle'"
                                                      [persons]='recipients$'
                                                      style="margin: -2px"
                                                      [showAvatarTitle]='true'></dvtx-message-panel-info-dropdown>
                  </ng-template>
                  <ng-template [ngIf]="_message.type !== ProcessEventType.ArtifactComment">
                    <dvtx-message-panel-action-dropdown [title]="'PROJECT_ROOM.ACTIONS' | translate"
                                                        class="ml-1"
                                                        [isClosed]="process?.status._code === 'closed' || readonly"
                                                        [isAuthor]="isFromCurrentUser()"
                                                        (onEditMode)="enableEditMode()"
                                                        [enableReplyButton]="_message?.enableReply"
                                                        (onReply)="toggleComment()"
                                                        (onDelete)="deleteComment()"></dvtx-message-panel-action-dropdown>
                  </ng-template>
                </div>
              </div>
            </div>
          </li>
        </ul>

        <!-- Message body -->
        <ul class="box-body" *ngIf="_message?.details || _message?.message">
          <!-- Reply to reference header -->
          <li class="details" *ngIf="_message?.replyTo">
            <div class="dvtx-fg-color dvtx-fg-fivef-blue-300">
              <em>{{ _message?.replyToAuthor }} {{ 'GENERAL.WROTE_AT' | translate }} {{ _message?.replyToDate | date:'medium' }}</em>
            </div>
            <div class="details-reply-to" [froalaView]="_message?.replyTo"></div>
          </li>

          <!-- Artifact performer reference header -->
          <li class="details pb-0" *ngIf="_message.type === ProcessEventType.ArtifactComment">
            <ng-container [ngSwitch]="lang">
              <ng-container *ngSwitchCase="'de'">
                <div class="dvtx-fg-color dvtx-fg-fivef-blue-300">
                  <em><strong>{{ avatar$?.name || avatar$?.email }}</strong> hat einen Kommentar zu Dokument
                    <strong [class.dvtx-active-artifact-link]="process"
                            (click)="openPreviewDialog()">{{ _message?.title }}</strong> hinzugefügt:
                  </em>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'en'">
                <div class="dvtx-fg-color dvtx-fg-fivef-blue-300">
                  <em><strong>{{ avatar$?.name || avatar$?.email }}</strong> has added a comment to document
                    <strong [class.dvtx-active-artifact-link]="process"
                            (click)="openPreviewDialog()">{{ _message?.title }}</strong>:
                  </em>
                </div>
              </ng-container>
            </ng-container>
          </li>

          <!-- Message body -->
          <li class="details">
            <span class="seen-by-overlay"
                  fivefMarkRead
                  [markReadMessage]="_message"
                  [markReadMessageFrom]="'process'"
                  (onMarkedRead)="updateComment(_message, $event)"></span>
            <ng-template [ngIf]="editMode" [ngIfElse]="viewMode" [formGroup]="form">
              <dvtx-emoji-input formControlName="content"
                                [value]="_message?.details || _message?.message"
                                [editMode]="true"
                                [processId]="process?.id"
                                [enableCancelButton]="true"
                                (onSave)="editComment()" (onCancel)="cancelEdit()"></dvtx-emoji-input>
            </ng-template>
            <ng-template #viewMode>
              <div [froalaView]="_message?.details || _message?.message"></div>
            </ng-template>
          </li>

          <!-- Seen by & reaction bar -->
          <li class="message-panel-footer d-flex align-items-center justify-content-between"
              *ngIf="!readonly && !editMode">
            <div class="message-panel-statistics">
              <fivef-reactions (onReaction)="react($event)"
                               [reactions]="reactions"></fivef-reactions>
              &nbsp;|&nbsp;
              <span class="d-flex align-items-center" [matTooltip]="'PROJECT_ROOM.SEEN_BY' | translate"
                    [matTooltipPosition]="'above'">
                <ng-container>
                  <dvtx-message-panel-info-dropdown [icon]="'visibility'" [showTitle]="true"
                                                    [title]="!(seenBy$ | async) ? '0' : '(' + (seenBy$ | async)?.length + ')'"
                                                    [persons]='seenBy$ | async' [showAvatarTitle]='true'
                                                    [class.active]="(seenBy$ | async)?.length > 0">
                  </dvtx-message-panel-info-dropdown>
                </ng-container>
              </span>
            </div>
          </li>

          <!-- Reply comment editor -->
          <li *ngIf="showInputComment" class="message-comment-area p-0">
            <dvtx-emoji-input [(ngModel)]="comment" [placeholder]="'MESSAGING.PLACEHOLDER' | translate"
                              [processId]="process?.id"
                              (onSend)="sendMessage()"></dvtx-emoji-input>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
