import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';

@Component({
  selector: 'dvtx-message-panel-info-dropdown',
  templateUrl: './message-panel-info-dropdown.component.html',
  host: {class: 'fivef-message-panel-info-dropdown'},
  styleUrls: ['./message-panel-info-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagePanelInfoDropdownComponent implements OnDestroy {
  _persons: BehaviorSubject<any[]> = new BehaviorSubject([]);
  showDropdown = false;

  @Input()
  showAvatarTitle;

  @Input()
  showTitle = false;

  @Input()
  title;

  @Input()
  icon;

  @Input()
  set persons(persons) {
    // NOTE: Array check as guard and for backwards compability
    let res = [];
    if (Array.isArray(persons)) {
      res = persons.filter(p => !!p && p.email)
    } else {
      const tmp = [persons];
      res = tmp.filter(p => !!p && p.email)
    }

    this._persons.next(res);
  };

  ngOnDestroy() {
    this._persons.complete();
  }
}
