import {Component, Input, Output, EventEmitter, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {FivefBoxComponent} from '../../widget/fivef-box/fivef-box.component';
import {MatRippleModule} from '@angular/material/core';

@Component({
  selector: 'fivef-settings-panel',
  standalone: true,
  imports: [CommonModule, MatIconModule, FivefBoxComponent, MatRippleModule],
  templateUrl: './fivef-settings-panel.component.html',
  styleUrls: ['./fivef-settings-panel.component.scss'],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class FivefSettingsPanelComponent {
  @Input()
  title = '';

  @Input()
  subtitle = '';

  @Input()
  icon = '';

  @Input()
  subtext = '';

  @Input()
  size?;

  @Input()
  colorProfile: string = 'default'

  @Output()
  panelDada: EventEmitter<any> = new EventEmitter();

  onPanelSelect(event) {
    this.panelDada.emit({ event });
  }
}
