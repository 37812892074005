import 'froala-editor/js/plugins.pkgd.min.js'
import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {EmojiModule} from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {MessagePanelComponent} from './containers/message-panel/message-panel.component';
import {CommentPanelComponent} from './containers/comment-panel/comment-panel.component';
import {EmojiInputComponent} from './components/emoji-input/emoji-input.component';
import {
  MessagePanelInfoDropdownComponent
} from './components/message-panel-info-dropdown/message-panel-info-dropdown.component';
import {InViewportModule} from 'ng-in-viewport';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {
  MessagePanelVisibilityDropdownComponent
} from './components/message-panel-visibility-dropdown/message-panel-visibility-dropdown.component';
import {
  MessagePanelActionDropdownComponent
} from './components/message-panel-action-dropdown/message-panel-action-dropdown.component';
import {ReactiveFormsModule} from '@angular/forms';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';
import {FetchCollectorItemDirective} from './directives/fetch-collector-item.directive';
import {FivefMarkReadDirective} from '../../../../lib/fivef-ui/input/fivef-mark-read.directive';
import {FivefReactionsComponent} from '../../../../lib/fivef-ui/comment/fivef-reactions/fivef-reactions.component';
import {FivefSeenbyComponent} from '../../../../lib/fivef-ui/comment/fivef-seenby/fivef-seenby.component';
import {FivefEmojiMenuComponent} from '../../../../lib/fivef-ui/input/fivef-emoji-menu/fivef-emoji-menu.component';
import {FivefMenuItemComponent} from '../../../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';

@NgModule({
  imports: [
    SharedModule,
    PickerModule,
    EmojiModule,
    InViewportModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule,
    FivefMarkReadDirective,
    FivefReactionsComponent,
    FivefSeenbyComponent,
    FivefEmojiMenuComponent,
    FivefMenuItemComponent
  ],
  declarations: [
    MessagePanelComponent,
    CommentPanelComponent,
    EmojiInputComponent,
    MessagePanelInfoDropdownComponent,
    MessagePanelVisibilityDropdownComponent,
    MessagePanelActionDropdownComponent,
    FetchCollectorItemDirective
  ],
  exports: [
    MessagePanelComponent,
    EmojiInputComponent,
    MessagePanelActionDropdownComponent,
    FetchCollectorItemDirective
  ]
})
export class InstantMessagingModule {
}
