import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Subject} from 'rxjs/internal/Subject';
import {FivefNotificationService} from 'app/lib/fivef-ui/notification/fivef-notification/fivef-notification.service';
import {Store} from '@ngrx/store';
import {AppState} from 'app/app.state';
import {User} from 'app/+store/user/user';
import {Process} from 'app/+store/process/process';
import {FivefAvatarService} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.service';
import {ProcessArtifactSelectors} from 'app/+store';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {DmsSyncStatus} from 'app/+store/process-artifact/process-artifact';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FivefSearchComponent} from '../../input/fivef-search/fivef-search.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {FivefFileSizePipe} from '../../util/fivef-file-size.pipe';
import {TableUtilsModule} from '../../../fivef-legacy/table-utils/table-utils.module';
import {FivefDeleteArtifactComponent} from '../fivef-delete-artifact/fivef-delete-artifact.component';
import {FivefArtifactDetailsComponent} from '../fivef-artifact-details/fivef-artifact-details.component';
import {FivefArtifactPreviewDialogComponent, PreviewViewType} from '../fivef-artifact-preview-dialog/fivef-artifact-preview-dialog.component';

export type ArtifactTableColumnType = 'icon'
  | 'dms-ident'
  | 'select'
  | 'name'
  | 'attached'
  | 'publicly-available'
  | 'title'
  | 'ea-counts'
  | 'export_available'
  | 'sync-status'
  | 'size'
  | 'type'
  | 'createdAt'
  | 'actions'
  | 'expandedDetail'
  | 'expand-details-action';

export interface IArtifactTableItem {
  id: string;
  title?: string;
  processTitle?: string;
  selected?: any;
  size?: number;
  publiclyAvailable?: boolean;
  syncStatus?: DmsSyncStatus;
  url?: string;
}

@Component({
  selector: 'fivef-artifact-table',
  templateUrl: './fivef-artifact-table.component.html',
  styleUrls: ['./fivef-artifact-table.component.scss'],
  standalone: true,
  imports: [CommonModule, MatIconModule, TranslateModule, FivefSearchComponent, MatTableModule, MatCheckboxModule, MatTooltipModule, MatButtonModule, CdkOverlayOrigin, CdkConnectedOverlay, FivefFileSizePipe, MatSortModule, TableUtilsModule, FivefDeleteArtifactComponent, FivefArtifactDetailsComponent, FivefArtifactPreviewDialogComponent],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FivefArtifactTableComponent implements OnInit, OnDestroy {
  private onDestroy = new Subject();

  @Input()
  displayedColumns: ArtifactTableColumnType[] = ['select', 'attached', 'name', 'title', 'size', 'type', 'createdAt', 'actions'];

  public dataSource: MatTableDataSource<IArtifactTableItem> = new MatTableDataSource([]);
  public selection = new SelectionModel<IArtifactTableItem>(true, []);
  public expandedElement = null;
  public selectedElement = null;
  public artifactIds = [];

  public PreviewViewType = PreviewViewType;
  public DmsSyncStatus = DmsSyncStatus;

  public query = '';
  public disabled = false;
  public showExportInfoMenu = {};

  @Input()
  public deleteEnabled = false;

  @Input()
  public showSearch = true;

  @Input()
  public showTaskAction = true;

  @Input()
  public process: Process;

  @Input()
  public isClosed = true;

  @Input()
  public showSelectionCounter = false

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  @Output()
  private onSelect = new EventEmitter<IArtifactTableItem[]>();

  @Output()
  private onRemove = new EventEmitter<IArtifactTableItem>();

  public eaCountMap = {};
  private currentUser$;

  private accessParams = null;

  private artifacts$ = new BehaviorSubject<IArtifactTableItem[]>([])
  private preselected$ = new BehaviorSubject<string[]>([])

  @Input()
  private set preselected(artifacts) {
    this.preselected$.next(artifacts);
  }

  @Input() set lock(disabled: boolean) {
    this.disabled = disabled;
    if (disabled) {
      this.masterToggle(true);
    }
  }

  @Input() set documents(artifacts: IArtifactTableItem[]) {
    const docs = artifacts && artifacts.length ? artifacts : [];
    this.artifactIds = docs.map(a => a.id);
    this.artifacts$.next(docs);
  }

  constructor(private router: Router,
              private store: Store<AppState>,
              private notifySvc: FivefNotificationService,
              private avatarService: FivefAvatarService,
              private dialogRef: MatDialog) {
    this.currentUser$ = store.select('currentUser').pipe(takeUntil(this.onDestroy)).subscribe((user: User) => {
      if (user) {
        this.accessParams = `?access_token=${user.accessToken}&client=${user.client}&uid=${user.uidToParam()}`;
      }
    });
  }

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    combineLatest(this.artifacts$, this.preselected$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([artifacts, preselected]) => this.processArtifacts(artifacts, preselected));
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.artifacts$.complete();
    this.preselected$.complete();
  }

  public applyFilter(filterValue: string) {
    this.query = filterValue;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public toggle($event, row) {
    if ($event && this.selection && this.selection) {
      this.selection.toggle(row);
      this.onSelect.emit(this.selection.selected);
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(selectAll = false) {
    this.isAllSelected() && !selectAll ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    this.onSelect.emit(this.selection.selected);
  }

  public deselectDetails($event, element) {
    $event.stopPropagation();
    if (this.expandedElement === element) {
      this.expandedElement = null;
    } else {
      this.expandedElement = element;
    }
  }

  public resetFilter() {
    this.applyFilter('');
  }

  public getAvatar(email) {
    return this.avatarService.getProfile(email);
  }

  public download(document: IArtifactTableItem) {
    if (this.accessParams) {
      const myRequest = new Request(`${document.url}${this.accessParams}`);
      fetch(myRequest).then((response) => {
        if (response.status === 200) {
          window.location.assign(`${response.url}`);
        } else {
          this.notifySvc.error('HTTP_ERROR.DEFAULT')
        }
      });
    }
  }

  public openTasksForArtifact(artifact) {
    try {
      this.dialogRef.closeAll();
    } catch (err) {
      console.error(err);
    }
    this.router.navigate([`/tasks/${this.process.id}/resource/${artifact.id}`]);
  }

  // Initial selection is empty except the document is selected by
  // property or preselection array.
  private processArtifacts(artifacts: IArtifactTableItem[], preselected: string[]) {
    const initialSelection: IArtifactTableItem[] = [];

    // Count of attached external links / Quickshares
    const eaCountMap = {};

    artifacts.forEach(doc => {
      this.eaCountMap[doc.id] = this.store.select(ProcessArtifactSelectors.getExternalAccessCountByDocumentId(doc.id));
      this.showExportInfoMenu[doc.id] = false;

      let selected = false;
      if (preselected && preselected.length > 0) {
        selected = preselected.find((id) => id === doc.id) ? true : false;
      } else {
        selected = doc.selected;
      }
      if (selected) {
        initialSelection.push(doc);
        this.selection.select(doc);
      }
    });

    // Emit the initial selection inside a new event cycle, to prevent circular updates.
    setTimeout(_ => {
      this.onSelect.emit(initialSelection);
    }, 1);

    this.dataSource.data = artifacts;
    this.eaCountMap = eaCountMap;
  }
}
