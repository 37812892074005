<a class="fivef-process-tree-node--link"
   (click)="$event.preventDefault(); selectNode(node)"
   [matTooltip]="node?.name">
  <ng-template [ngIf]="node?.closed" [ngIfElse]="workflowIcon">
    <div class="fivef-process-tree-node--icon-container">
      <span class="fivef-process-tree-node--icon fivef-process-tree-node--closed">
        <mat-icon>lock</mat-icon>
      </span>
    </div>
  </ng-template>

  <ng-template #workflowIcon>
    <div class="fivef-process-tree-node--icon-container"
         [class.dvtx-active-link-icon]="node?.active">
      <ng-template [ngIf]="node?.isSvgIcon" [ngIfElse]="defaultIconType">
        <span class="fivef-process-tree-node--icon">
          <mat-icon [svgIcon]="node?.icon"></mat-icon>
        </span>
      </ng-template>
      <ng-template #defaultIconType>
        <span class="fivef-process-tree-node--icon"
              [class.dvtx-active-link-icon]="node?.active">
          <mat-icon>{{ node?.icon }}</mat-icon>
        </span>
      </ng-template>
    </div>
  </ng-template>

  <span class="fivef-process-tree-node--title"
        [class.fivef-process-tree-node--active]="node?.active">
    <ng-template [ngIf]="node?.name" [ngIfElse]="nameMissing">
      <ng-container *ngIf="node.name.length">
        {{ node?.name }}
      </ng-container>
    </ng-template>
    <ng-template #nameMissing>
      <em class="fivef-process-tree-node--no-title">{{ noTitlePlaceholder }}</em>
    </ng-template>
  </span>
</a>
