import {NgModule} from '@angular/core';
import {MessageSettingsComponent} from './containers/message-settings/message-settings.component';
import {LayoutModule} from '@angular/cdk/layout';
import {InstantMessagingModule} from '../instant-messaging/instant-messaging.module';
import {SignatureModule} from '../../../signature/signature.module';
import {OrganizationModule} from 'app/modules/organization/organization.module';
import {FivefPageSidebarModule} from 'app/lib/fivef-ui/page/fivef-page-sidebar/fivef-page-sidebar.module';
import {FivefAvatarModule} from 'app/lib/fivef-ui/profile/fivef-avatar/fivef-avatar.module';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';
import {FiltersContainerModule} from 'app/lib/fivef-legacy/filters/filters-container.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {CommonModule} from '@angular/common';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    LayoutModule,
    InstantMessagingModule,
    SignatureModule,
    OrganizationModule,
    FivefPageSidebarModule,
    FivefAvatarModule,
    TooltipModule,
    FiltersContainerModule,
    TranslateModule,
    MatSlideToggleModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatSelectModule,
    FormsModule
  ],
  declarations: [
    MessageSettingsComponent,
  ],
  exports: [
    InstantMessagingModule,
    MessageSettingsComponent
  ]
})
export class NotificationSettingsModule {
}
