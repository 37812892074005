import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {FilePickerModule} from 'app/modules/file-picker/file-picker.module';
import {TaskViewerComponent} from './container/task-viewer/task-viewer.component';
import {TaskStatusComponent} from './components/tabs/task-status/task-status.component';
import {TaskSettingsComponent} from './components/tabs/task-settings/task-settings.component';
import {TaskActivityLogComponent} from './components/tabs/task-activity-log/task-activity-log.component';
import {TaskResourcesComponent} from './components/task-resources/task-resources.component';
import {TaskAssignmentResponseComponent} from './components/task-assignment-response/task-assignment-response.component';
import {TaskAssignmentsComponent} from './components/task-assignments/task-assignments.component';
import {TaskAssignmentStatusBarComponent} from './components/task-assignment-status-bar/task-assignment-status-bar.component';
import {TaskStatusBarComponent} from './components/task-status-bar/task-status-bar.component';
import {TaskActivityItemComponent} from './components/task-activity-item/task-activity-item.component';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';
import {LabelsModule} from 'app/lib/fivef-legacy/labels/labels.module';
import {FivefStatusSelectorComponent} from '../../../../lib/fivef-ui/process/fivef-status-selector/fivef-status-selector.component';
import {FivefCommentsComponent} from '../../../../lib/fivef-ui/comment/fivef-comments/fivef-comments.component';
import {FivefLabelComponent} from '../../../../lib/fivef-ui/label/fivef-label/fivef-label.component';
import {FivefLabelsComponent} from '../../../../lib/fivef-ui/label/fivef-labels/fivef-labels.component';
import {FivefLabelsSelectorComponent} from '../../../../lib/fivef-ui/label/fivef-labels-selector/fivef-labels-selector.component';
import {FivefContextSidebarComponent} from '../../../../lib/fivef-ui/common/fivef-context-sidebar/fivef-context-sidebar.component';
import {FivefContextSidebarContentDirective} from '../../../../lib/fivef-ui/common/fivef-context-sidebar/fivef-context-sidebar-content.directive';
import {FivefItemSelectorComponent} from '../../../../lib/fivef-ui/input/fivef-item-selector/fivef-item-selector.component';
import {FivefLabelsBarComponent} from '../../../../lib/fivef-ui/label/fivef-labels-bar/fivef-labels-bar.component';
import {FivefRichTextComponent} from '../../../../lib/fivef-ui/input/fivef-rich-text/fivef-rich-text.component';
import {FivefDatepickerComponent} from '../../../../lib/fivef-ui/input/fivef-datepicker/fivef-datepicker.component';
import {FivefInputCeComponent} from '../../../../lib/fivef-ui/input/fivef-input-ce/fivef-input-ce.component';
import {FivefUploadComponent} from '../../../../lib/fivef-ui/upload/fivef-upload/fivef-upload.component';
import {FivefFileSizePipe} from '../../../../lib/fivef-ui/util/fivef-file-size.pipe';
import {FivefAlertComponent} from '../../../../lib/fivef-ui/util/fivef-alert/fivef-alert.component';
import {FivefInfoBlockComponent} from '../../../../lib/fivef-ui/util/fivef-info-block/fivef-info-block.component';

@NgModule({
  imports: [
    SharedModule,
    FilePickerModule,
    TooltipModule,
    LabelsModule,
    FivefStatusSelectorComponent,
    FivefCommentsComponent,
    FivefLabelComponent,
    FivefLabelsComponent,
    FivefLabelsSelectorComponent,
    FivefContextSidebarComponent,
    FivefContextSidebarContentDirective,
    FivefInfoBlockComponent,
    FivefItemSelectorComponent,
    FivefLabelsBarComponent,
    FivefRichTextComponent,
    FivefDatepickerComponent,
    FivefInputCeComponent,
    FivefUploadComponent,
    FivefFileSizePipe,
    FivefAlertComponent
  ],
  declarations: [
    TaskViewerComponent,
    TaskStatusComponent,
    TaskSettingsComponent,
    TaskActivityLogComponent,
    TaskResourcesComponent,
    TaskAssignmentResponseComponent,
    TaskAssignmentsComponent,
    TaskAssignmentStatusBarComponent,
    TaskStatusBarComponent,
    TaskActivityItemComponent
  ],
  exports: [
    TaskViewerComponent,
    TaskStatusComponent,
    TaskSettingsComponent
  ]
})
export class TasksViewerModule {
}
