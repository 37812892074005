import {Injectable, NgZone} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {TaskBuilder} from '../../../../+store/task/task.builder';
import {CacActions, CollectorAttachmentActions, ProcessArtifactActions, QuickshareAttachmentActions, TaskActions} from '../../../../+store';
import {QuickshareAttachmentBuilder} from '../../../../+store/quickshare-attachment/quickshare-attachment.builder';
import {ProcessArtifactBuilder} from '../../../../+store/process-artifact/process-artifact.builder';
import {ProcessArtifactUpdateMode} from './fivef-upload.component';
import {Process, UploadType} from '../../../../+store/process/process';
import {FivefNotificationService} from '../../notification/fivef-notification/fivef-notification.service';
import {ProcessArtifact} from '../../../../+store/process-artifact/process-artifact';
import {CollectorAttachmentBuilder} from '../../../../+store/collector-attachment/collector-attachment.builder';
import {CacBuilder} from '../../../../+store/cac/cac.builder';
import {IThirdPartyConfirmation} from '../../../../modules/third-party-confirmation/models/thirdPartyConfirmation';
import {ResourceBuilder} from '../../../../+store/_legacy/api/builders/resource.builder';
import {EnvService} from '../../../fivef-net/fivef-api-resource/services/env.service';
import {environment} from '../../../../../environments/environment';

/**
 * Upload helper service.
 * Attention shared between instances. All methods must be purely functional.
 */
@Injectable()
export class FivefUploadService {

  constructor(private store: Store<AppState>,
              private env: EnvService,
              private notifyService: FivefNotificationService,
              private ngZone: NgZone) { }

  public getUrl(uploadType: UploadType, token: string, isDraft: boolean, processId: string, resourceId: string, role: string): string {
    if (token && uploadType === 'large_file') {
      return `${this.env.apiBase()}/api/v1/file_inbox/${token}/upload`;

    } else if (uploadType === 'third_party_email_attachment') {
      // E-Mail attachment upload of Thrid party wizard third step.
      return `${this.env.apiBase()}/api/v1/audit/third_party_confirmations/${processId}/email_attachment`;

    } else if (token && uploadType === 'third_party') {
      return `${this.env.apiBase()}/api/v1/audit/annual_auditing/contribute/${token}/upload?reference_id=${resourceId}`;

    } else if (token) {
      return `${this.env.apiBase()}/api/v1/access/external/upload?access_token=${token}`;

    } else {
      return `${this.env.apiBase()}${Process.uploadUrl(processId, uploadType, isDraft, role, resourceId)}`;
    }
  }

  public handleComplete(processId: string, parentId: string, isDraft: boolean, processArtifactUpdateMode: ProcessArtifactUpdateMode, uploadType: UploadType, response): ProcessArtifact | IThirdPartyConfirmation | null {
    let artifact = null;

    if (uploadType === 'task') {
      const data = JSON.parse(response);

      const builder = new TaskBuilder();
      builder.addIncludedSection(data.included);
      const taskObj = builder.fromResponse(data.data);
      this.ngZone.runOutsideAngular(() => this.store.dispatch(new TaskActions.EditSuccess(taskObj)));
      this.notifyService.success('UPLOAD.SUCCESS_DEFAULT');

    } else if (uploadType === 'quickshare') {
      const builder = new QuickshareAttachmentBuilder();
      const attachment = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new QuickshareAttachmentActions.LoadOneSuccess(attachment));

    } else if (uploadType === 'collector' &&  isDraft) {
      const builder = new CollectorAttachmentBuilder();
      const attachment = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new CollectorAttachmentActions.LoadOneSuccess(attachment));

    } else if (uploadType === 'cac' && isDraft) {
      const builder = new CacBuilder();
      builder.addIncludedSection(JSON.parse(response).included);
      const cac = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new CacActions.LoadOneSuccess(cac));

    } else if (parentId && processArtifactUpdateMode !== ProcessArtifactUpdateMode.None) {
      const recursive = processArtifactUpdateMode === ProcessArtifactUpdateMode.Recursive;
      this.store.dispatch(new ProcessArtifactActions.LoadAll(processId, recursive, true, false));

    } else if (uploadType === 'third_party_email_attachment') {
      const confirmationRequest: IThirdPartyConfirmation = <IThirdPartyConfirmation>ResourceBuilder.fromResponse(JSON.parse(response));
      return confirmationRequest;

    } else {
      // Regular case: Add uploaded artifact to store.
      const builder = new ProcessArtifactBuilder(processId);
      artifact = builder.fromResponse(JSON.parse(response).data);
      this.store.dispatch(new ProcessArtifactActions.LoadOneSuccess(artifact));
      this.notifyService.success('UPLOAD.SUCCESS_DEFAULT');
    }

    return artifact;
  }
}
