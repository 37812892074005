import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TreeData} from '../../../../../modules/process-tree/common/process-tree-data';

@Component({
  selector: 'fivef-process-tree-node',
  templateUrl: './fivef-process-tree-node.component.html',
  styleUrls: ['./fivef-process-tree-node.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FivefProcessTreeNodeComponent implements OnInit {
  @Input()
  node: TreeData;

  @Input()
  noTitlePlaceholder;

  @Output()
  onSelect = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
  }

  public selectNode(node) {
    if (!node) return;

    this.onSelect.emit(node);
  }
}
