import {NgModule} from '@angular/core';
import {SharedModule} from 'app/lib/fivef-legacy/shared/shared.module';
import {DocumentModule} from '../document/document.module';
import {FolderTreeIconComponent} from './components/folder-tree-icon/folder-tree-icon.component';
import {DocumentStructureComponent} from './containers/documents-structure/document-structure.component';
import {ProcessTreeNodeComponent} from './components/process-tree-node/process-tree-node.component';
import {ProcessTreeSingleSelectComponent} from './components/process-tree-single-select/process-tree-single-select.component';
import {MatTreeModule} from '@angular/material/tree';
import {FolderSelectionDialogComponent} from './components/folder-selection-dialog/folder-selection-dialog.component';
import {FolderSelectorComponent} from './components/folder-selector/folder-selector.component';
import {FolderTreeComponent} from './containers/folder-tree/folder-tree.component';
import {FivefFileSizePipe} from '../../../../lib/fivef-ui/util/fivef-file-size.pipe';
import {FivefMenuItemComponent} from '../../../../lib/fivef-ui/navigation/fivef-menu-item/fivef-menu-item.component';
import {FivefArtifactTableComponent} from '../../../../lib/fivef-ui/artifact/fivef-artifacts-table/fivef-artifact-table.component';
import {FivefInfoBlockComponent} from '../../../../lib/fivef-ui/util/fivef-info-block/fivef-info-block.component';

@NgModule({
  imports: [
    SharedModule,
    DocumentModule,
    MatTreeModule,
    FivefInfoBlockComponent,
    FivefFileSizePipe,
    FivefMenuItemComponent,
    FivefArtifactTableComponent
  ],
  declarations: [
    FolderTreeIconComponent,
    ProcessTreeNodeComponent,
    ProcessTreeSingleSelectComponent,
    DocumentStructureComponent,
    FolderSelectionDialogComponent,
    FolderSelectorComponent,
    FolderTreeComponent
  ],
  exports: [
    DocumentStructureComponent,
    FolderSelectionDialogComponent,
    FolderSelectorComponent
  ]
})
export class FolderModule {
}
