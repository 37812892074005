<div class="listing-box card">
  <fivef-box>
    <box-header>
      <div class="listing-box__box-header fivef-settings-panel--profile fivef-settings-panel--profile-{{colorProfile}}"
           matRipple
           (click)="onPanelSelect($event)">
        <div class="title-icon">
          <ng-container [ngSwitch]="icon">
            <ng-container *ngSwitchCase="'sign'">
              <mat-icon svgIcon="sign"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'bookman_cockpit'">
              <mat-icon svgIcon="bookman_cockpit"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'fibu_workflow'">
              <mat-icon svgIcon="fibu_workflow"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'projectroom'">
              <mat-icon svgIcon="projectroom"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'tasks'">
              <mat-icon svgIcon="tasks"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'labels'">
              <mat-icon svgIcon="labels"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'templates'">
              <mat-icon svgIcon="templates"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'fastdocs'">
              <mat-icon svgIcon="fastdocs"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'lohn'">
              <mat-icon svgIcon="lohn"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'insertChartOutlined'">
              <mat-icon svgIcon="insertChartOutlined"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'manage_accounts'">
              <mat-icon svgIcon="manage_accounts"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'datev_logo'">
              <mat-icon svgIcon="datev_logo"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'datev_data_service_logo'">
              <mat-icon svgIcon="datev_data_service_logo"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'monitor_heart'">
              <mat-icon svgIcon="monitor_heart"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'policy'">
              <mat-icon svgIcon="policy"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'fivef'">
              <mat-icon svgIcon="fivef"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'fivef_desktop'">
              <mat-icon svgIcon="fivef_desktop"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'elo_dms'">
              <mat-icon svgIcon="elo_dms"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'login'">
              <mat-icon svgIcon="login"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <mat-icon>{{icon}}</mat-icon>
            </ng-container>
          </ng-container>
        </div>
        <div class="box-header-title">
          <div class="events-amount">{{size}}</div>
          <div class="simple-panel-title title">{{title}}</div>
          <div class="simple-panel-title subtitle">{{subtitle}}</div>
          <div class="subtext">{{subtext}}</div>
        </div>
      </div>
    </box-header>
  </fivef-box>
</div>
