import {NgModule} from '@angular/core';
import {SharedModule} from '../../lib/fivef-legacy/shared/shared.module';
import {CreateTaskDialogComponent} from './component/create-task-dialog/create-task-dialog.component';
import {TasksComponent} from './containers/tasks/tasks.component';
import {ProcessDialogModule} from 'app/modules/workflow-engine/modules/process-dialog/process-dialog.module';
import {MyTasksContainerComponent} from './containers/my-tasks-container/my-tasks-container.component';
import {PersonsFilterModule} from 'app/lib/fivef-legacy/persons-filter/persons-filter.module';
import {CreateTaskButtonComponent} from './component/create-task-button/create-task-button.component';
import {FilePickerModule} from 'app/modules/file-picker/file-picker.module';
import {InViewportModule} from 'ng-in-viewport';
import {TasksListingHeadlineComponent} from './component/tasks-listing-headline/tasks-listing-headline.component';
import {DashboardStatsModule} from './dashboard-stats/dashboard-stats.module';
import {TasksViewerModule} from './modules/task-viewer/task-viewer.module';
import {ListingTasksModule} from './modules/listing-tasks/listig-tasks.module';
import {ProcessTasksContainerComponent} from './containers/process-tasks-container/process-tasks-container.component';
import {TooltipModule} from 'app/lib/fivef-legacy/tooltip/tooltip.module';
import {ClientsFilterModule} from 'app/lib/fivef-legacy/clients-filter/clients-filter.module';
import {LabelsModule} from 'app/lib/fivef-legacy/labels/labels.module';
import {FiltersContainerModule} from 'app/lib/fivef-legacy/filters/filters-container.module';
import {TasksFiltersModule} from './modules/tasks-filters/tasks-filters.module';
import {FivefTaskTreeComponent} from '../../lib/fivef-ui/task/fivef-task-tree/fivef-task-tree.component';
import {FivefIconMessageBlockComponent} from '../../lib/fivef-ui/util/fivef-icon-message-block/fivef-icon-message-block.component';
import {FivefLoadingIndicatorComponent} from '../../lib/fivef-ui/util/fivef-loading-indicator/fivef-loading-indicator.component';
import {FivefBackComponent} from '../../lib/fivef-ui/navigation/fivef-back/fivef-back.component';


@NgModule({
  imports: [
    SharedModule,
    ProcessDialogModule,
    PersonsFilterModule,
    FilePickerModule,
    InViewportModule,
    DashboardStatsModule,
    TasksViewerModule,
    ListingTasksModule,
    TooltipModule,
    ClientsFilterModule,
    LabelsModule,
    FiltersContainerModule,
    TasksFiltersModule,
    FivefTaskTreeComponent,
    FivefIconMessageBlockComponent,
    FivefLoadingIndicatorComponent,
    FivefBackComponent
  ],
  declarations: [
    TasksComponent,
    ProcessTasksContainerComponent,
    MyTasksContainerComponent,
    CreateTaskDialogComponent,
    CreateTaskButtonComponent,
    TasksListingHeadlineComponent,
  ],
  exports: [
    TasksComponent,
    ProcessTasksContainerComponent,
    MyTasksContainerComponent,
    CreateTaskDialogComponent,
    CreateTaskButtonComponent,
  ]
})
export class TasksModule {
}
