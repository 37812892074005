<div class="row">
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/addressbook/contacts']"
    [title]="'ORGANIZATION_NAVIGATON.ADDRESS_BOOK' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.ADDRESS_BOOK_SUBTEXT' | translate"
    [icon]="'perm_contact_calendar'"></fivef-settings-panel>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/orgSettings']"
    [title]="'ORGANIZATION_NAVIGATON.PROFIL' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.PROFIL_SUBTEXT' | translate"
    [icon]="'domain'"></fivef-settings-panel>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/storage/main']"
    [title]="'ORGANIZATION_NAVIGATON.DMS' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.DMS_SUBTEXT' | translate"
    [icon]="'folder_open'"></fivef-settings-panel>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/employees']"
    [title]="'ORGANIZATION_NAVIGATON.EMPLOYEES' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.EMPLOYEES_SUBTEXT' | translate"
    [icon]="'people'"></fivef-settings-panel>
  <!--  <ng-container *ngIf="(featureSet$ | async)?.hasProcessManagementSupport">-->
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights || !(projectRoomAdminOwnership$ | async)"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/process_management/admin/' + (orgaId | async) + '/overview']"
    [title]="'PROCESS_MANAGEMENT.MANAGE_PROJECT_ROOMS' | translate"
    [subtext]="'PROCESS_MANAGEMENT.MANAGE_PROJECT_ROOMS_SUBTITLE' | translate"
    [icon]="'projectroom'"></fivef-settings-panel>
  <!--  </ng-container>-->
  <ng-container *ngIf="(featureSet$ | async)?.hasTenantAdminSupport">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights || !(projectRoomAdminOwnership$ | async)"
      [fivefFeatureAuthorization]="'hasTenantAdminSupport'"
      [fivefRequiredLicence]="'Professional'"
      [fivefRouteLink]="tenantRoute"
      [title]="'TENANT.ADMIN.ADMINISTRATION' | translate"
      [subtext]="'TENANT.ADMIN.ADMINISTRATION_SUBTITLE' | translate"
      [icon]="'account_balance'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasLicenceManagement">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefRequiredLicence]="'Standard'"
      [fivefRouteLink]="['/organization/' + (orgaId | async) + '/licenses']"
      [title]="'ORGANIZATION_NAVIGATON.LICENSES' | translate"
      [subtext]="'ORGANIZATION_NAVIGATON.LICENSES_SUBTEXT' | translate"
      [icon]="'verified_user'"></fivef-settings-panel>
  </ng-container>

  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch custom-icon-content"
    style="cursor: pointer;"
    *dvtxFeatureToggle="false"
    [fivefRequiredLicence]="'Professional'"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [title]="'ORGANIZATION_NAVIGATON.TAGS' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.TAGS_SUBTEXT' | translate"
    [icon]="'#'"></fivef-settings-panel>
  <!-- [routerLink]="'/organization/' + (orgaId | async) + '/tags'" -->
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [fivefRequiredLicence]="'Professional'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/customization/logo']"
    [title]="'ORGANIZATION_NAVIGATON.CUSTOMIZATION' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.CUSTOMIZATION_SUBTEXT' | translate"
    [icon]="'settings_applications'"></fivef-settings-panel>
  <ng-container *ngIf="(featureSet$ | async)?.hasLabels">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefRequiredLicence]="'Standard'"
      [fivefRouteLink]="['/organization/' + (orgaId | async) + '/labels-settings']"
      [title]="'ORGANIZATION_NAVIGATON.LABELS_SETTINGS' | translate"
      [subtext]="'ORGANIZATION_NAVIGATON.LABELS_SETTINGS_SUBTEXT' | translate"
      [icon]="'labels'"></fivef-settings-panel>
  </ng-container>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/organization/' + (orgaId | async) + '/tasks-settings']"
    [title]="'ORGANIZATION_NAVIGATON.TASKS_SETTINGS' | translate"
    [subtext]="'ORGANIZATION_NAVIGATON.TASKS_SETTINGS_SUBTEXT' | translate"
    [icon]="'tasks'"></fivef-settings-panel>
  <fivef-settings-panel
    class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
    style="cursor: pointer;"
    [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
    [fivefRequiredLicence]="'Standard'"
    [fivefRouteLink]="['/templates-management/' + (orgaId | async) + '/overview']"
    [title]="'WORKFLOW_TEMPLATE.TEMPLATE_MANAGEMENT' | translate"
    [subtext]="'WORKFLOW_TEMPLATE.TEMPLATE_MANAGEMENT_SUBTEXT' | translate"
    [icon]="'templates'"></fivef-settings-panel>
  <ng-container *ngIf="davSync">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [fivefRequiredLicence]="'Standard'"
      [fivefRouteLink]="['/organization/' + (orgaId | async) + '/contact_imports/dav']"
      [title]="'ORGANIZATION_NAVIGATON.IMPORT' | translate"
      [subtext]="'ORGANIZATION_NAVIGATON.IMPORT_SUBTEXT' | translate"
      [icon]="'import_export'"></fivef-settings-panel>
  </ng-container>
  <!-- *ngIf="(blog$ | async)?.hasCms"-->
  <ng-container *ngIf="(featureSet$ | async)?.hasCms">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefFeatureAuthorization]="'hasCms'"
      [fivefFeatureRoute]="['/cms/admin/' + (orgaId | async) + '/overview']"
      [title]="'CMS.CMS' | translate"
      [subtext]="'CMS.CMS_SUBTITLE' | translate"
      [icon]="'subject'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasFastdocsSettings">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefFeatureAuthorization]="'hasFastdocsSettings'"
      [fivefFeatureRoute]="['/fastdocs/admin/' + (orgaId | async) + '/settings']"
      [title]="'FASTDOCS.SETTINGS_TITLE' | translate"
      [subtext]="'FASTDOCS.SETTINGS_SUBTITLE' | translate"
      [icon]="'lohn'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasDigitalbarSettings && (featureSet$ | async)?.hasDigitalbar">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [fivefFeatureAuthorization]="'hasDigitalbarSettings'"
      [fivefFeatureRoute]="['/digitalbar/admin/' + (orgaId | async) + '/settings']"
      [title]="'DIGITALBAR.SETTINGS_TITLE' | translate"
      [subtext]="'DIGITALBAR.SETTINGS_SUBTITLE' | translate"
      [icon]="'link'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasFivefAgentSettings && (featureSet$ | async)?.hasFivefAgent">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [fivefFeatureAuthorization]="'hasFivefAgentSettings'"
      [fivefFeatureRoute]="['/fivef-agent/admin/' + (orgaId | async) + '/settings']"
      [title]="'FIVEF_AGENT.SETTINGS_TITLE' | translate"
      [subtext]="'FIVEF_AGENT.SETTINGS_SUBTITLE' | translate"
      [icon]="'link'"></fivef-settings-panel>
  </ng-container>

  <!-- hasFivefDesktop enables the feature on the organization.  hasFivefDesktopSettings enables the settings/PIN-panel
       for the employee. Both are needed to show this panel. -->
  <ng-container *ngIf="(featureSet$ | async)?.hasFivefDesktopSettings && (featureSet$ | async)?.hasFivefDesktop">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [fivefFeatureAuthorization]="'hasFivefDesktopSettings'"
      [fivefFeatureRoute]="['/fivef-desktop/admin/' + (orgaId | async) + '/settings']"
      [title]="'FIVE_F.FIVEF_DESKTOP.FIVEF_DESKTOP' | translate"
      [subtext]="'FIVE_F.FIVEF_DESKTOP.SETTINGS' | translate"
      [icon]="'fivef_desktop'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasEloDms"> <!-- && (featureSet$ | async)?.hasDatevDmsSettings">-->
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefFeatureAuthorization]="'hasEloDms'"
      [fivefFeatureRoute]="['/elo-dms/' + (orgaId | async) + '/settings']"
      [title]="'ELO.SETTINGS' | translate"
      [subtext]="'ELO.SETTINGS_SUB' | translate"
      [icon]="'elo_dms'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasDatevDms"> <!-- && (featureSet$ | async)?.hasDatevDmsSettings">-->
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefFeatureAuthorization]="'hasDatevDms'"
      [fivefFeatureRoute]="['/datev-dms/' + (orgaId | async) + '/keywords']"
      [title]="'DATEV.DMS.PROCESS_SETTINGS' | translate"
      [subtext]="'DATEV.DMS.SETTINGS_SUBTITLE' | translate"
      [icon]="'datev_data_service_logo'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasDatevSettings && (featureSet$ | async)?.hasDatev">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [fivefFeatureAuthorization]="'hasDatevSettings'"
      [fivefFeatureRoute]="['/datev/' + (orgaId | async) + '/user']"
      [title]="'DATEV.SETTINGS_DUO_TITLE' | translate"
      [subtext]="'DATEV.SETTINGS_SUBTITLE' | translate"
      [icon]="'datev_data_service_logo'"></fivef-settings-panel>
  </ng-container>

  <ng-container *ngIf="(featureSet$ | async)?.hasBookman">
    <fivef-settings-panel
      class="col-12 col-sm-6 col-lg-4 align-self-md-stretch"
      style="cursor: pointer;"
      [class.disabled]="!(myMembership | async)?.hasAdministrationRights"
      [fivefFeatureAuthorization]="'hasBookman'"
      [fivefFeatureRoute]="['/organization/' + (orgaId | async) + '/bookman-cockpit-settings']"
      [title]="'USER_NAVIGATON.BOOKMAN_COCKPIT_SETTINGS' | translate"
      [subtext]="'USER_NAVIGATON.BOOKMAN_COCKPIT_SETTINGS_SUBTITLE' | translate"
      [icon]="'fibu_workflow'"></fivef-settings-panel>
  </ng-container>
</div>
