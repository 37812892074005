import {NgModule} from '@angular/core';
import {FivefProcessTreeSidebarWidgetComponent} from './fivef-process-tree-sidebar-widget/fivef-process-tree-sidebar-widget.component';
import {FivefProcessTreeSelectComponent} from './fivef-process-tree-select/fivef-process-tree-select.component';
import {MatTreeModule} from '@angular/material/tree';
import {FivefProcessTreeNodeComponent} from './fivef-process-tree-node/fivef-process-tree-node.component';
import {FivefProcessTreeSelectContainerComponent} from './process-structure-standalone-tree/fivef-process-tree-select-container.component';
import {MatRadioModule} from '@angular/material/radio';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {CommonModule} from '@angular/common';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';

@NgModule({
  imports: [
    CommonModule,
    MatTreeModule,
    MatRadioModule,
    TranslateModule,
    MatIconModule,
    MatExpansionModule,
    MatTooltipModule,
    MatMenuModule,
    MatCheckboxModule
  ],
  declarations: [
    FivefProcessTreeSidebarWidgetComponent,
    FivefProcessTreeSelectComponent,
    FivefProcessTreeNodeComponent,
    FivefProcessTreeSelectContainerComponent
  ],
  exports: [
    FivefProcessTreeSidebarWidgetComponent,
    FivefProcessTreeSelectContainerComponent
  ]
})
export class FivefProcessNavigationModule {
}
