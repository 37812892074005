import {IApiResourceBuilder} from 'app/lib/fivef-net/fivef-api-resource/models/api.interface';
import {DMS_ACCOUNT_TYPE_MAP, DmsFolder} from './dms-folder';
import {DmsAccountType} from './dms-folder.interface';
import {ProcessBuilder} from '../process/process.builder';

export class DmsFolderBuilder implements IApiResourceBuilder<DmsFolder> {
  private sourceId: string;

  constructor(public dmsAccountType: DmsAccountType) {}

  fromResponse(data): DmsFolder {
    const folder = new DmsFolder(
      data.id,
      data.attributes.name,
      data.attributes.path,
      data.attributes.source_id,
      this.dmsAccountType,
      data.attributes.parent_id,
      data.attributes.folder_count,
      data.attributes.process_count,
      data.attributes.document_count,
      data.attributes.shared || false,
      data.attributes.audit_proof,
      data.attributes.created_at,
      data.attributes.updated_at);

    folder.userProcessesSize = data.attributes.user_processes_size;
    folder.ownedBy = data.attributes.owned_by;
    folder.bookmanCockpitEnabled = data.attributes.bookman_cockpit_enabled;

    folder.auditProof = data.attributes.audit_proof;
    folder.auditProofUntil = data.attributes.audit_proof_until;

    if (data.attributes.user_process_map) {
      const builder = new ProcessBuilder(null);
      folder.userProcessMap = data.attributes.user_process_map.map(p => {
        return builder.fromResponse((p.data));
      });
    }
    folder.allProcessesSize = data.attributes.all_processes_size;
    folder.furtherProcessesSize = data.attributes.further_processes_size;
    folder.users = data.attributes.users;
    folder.admin = data.attributes.admin;

    // API Change: Source ID and DMS account type are now send by meta section
    // to reduce bandwidth (both values are the same for each element).
    // On 5200 folder this reduces the transferred data from 2.8MB to 2.3MB.
    if (this.sourceId) {
      folder.sourceId = this.sourceId;
      folder.dmsAccountType = this.dmsAccountType;
    }

    return folder;
  }

  toRequest(_: DmsFolder) {
    return null;
  }

  addMetaSection(meta: any) {
    if (!meta) return;
    this.sourceId = meta.source_id;
    this.dmsAccountType = DMS_ACCOUNT_TYPE_MAP[meta.dms_account_type];
  }

  /**
   * Creates a tree structure of folder Objects.
   * Returns a root folder with all its subfolders as children.
   *
   * Expects an array of API folder definitions.
   *
   * @param response
   * @returns {DMSFolder}
   */
  /* Algorithm.
   * 1. Iterate over the folder array of the response.
   *    For each entry create a DMSFolder object.
   *    If you find the root folder (parent_id == null)
   *    remember it it will be returned.
   *    Create a index of all folder ids to create the recursive structure afterwards.
   *    Remember all created folders in a catalog for the second iteration.
   * 2. Create the tree structure by iteration over the folder catalog.
   *    if a parentId is present, then add the current folder to the parent
   *    by searching it over the index.
   * 3. Return the root node.
   */
  static createFolderTreeFrom(folders: DmsFolder[]): DmsFolder {
    let root = null;
    const folderIndex = {};
    const folderCatalog = [];

    folders.forEach((folder) => {
      if (!folder.parentId) {
        root = folder;
      }
      folderIndex[folder.id] = folder;
      folderCatalog.push(folder);
    });

    folderCatalog.forEach((folder) => {
      if (folder.parentId) {
        folderIndex[folder.parentId].children.push(folder);
      }
    });
    return root;
  }
}
