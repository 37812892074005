import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {distinctUntilChanged, filter, switchMap, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {CollectorItemActions, CommentActions, CommentSelectors} from '../../../../+store';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../app.state';
import {Comment} from '../../../../+store/comment/comment';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {Subject} from 'rxjs/internal/Subject';
import {CommentReactionType, CommentResourceType, CommentType} from '../../../../+store/comment/comment.interface';
import {of} from 'rxjs/internal/observable/of';

@Injectable()
export class FivefCommentsRepository implements OnDestroy {
  private onDestroy = new Subject<void>();

  private processId$ = new BehaviorSubject<string>(null);
  private resourceId$ = new BehaviorSubject<string>(null);
  private resourceType$ = new BehaviorSubject<CommentResourceType>(null);

  public loading: Observable<boolean>;

  public comments: Observable<Comment[]>;

  private restoreMessageOnFailure$ = new BehaviorSubject<string>(null);
  public restoreMessageOnFailure: Observable<string> = this.restoreMessageOnFailure$.asObservable();

  private _processId: string;
  private _resourceId: string;
  private _resourceType: CommentResourceType;

  constructor(private store: Store<AppState>) {
    this.init();
  }

  ngOnDestroy() {
    this.processId$.complete();
    this.resourceId$.complete();
    this.resourceType$.complete();
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  set processId(pid: string) {
    this._processId = pid;
    this.processId$.next(pid);
  }

  set resourceId(rid: string) {
    this._resourceId = rid;
    this.resourceId$.next(rid);
  }

  set resourceType(type: CommentResourceType) {
    this._resourceType = type;
    this.resourceType$.next(type);
  }

  public fetchComments(processId: string, resourceId: string, resourceType: CommentResourceType) {
    if (resourceType === 'node' && processId && resourceId) {
      this.store.dispatch(new CommentActions.LoadAll(processId, false, resourceId));
    }

    if (resourceType === 'artifact' && !!resourceId) {
      this.store.dispatch(new CommentActions.LoadAll(resourceId, false, null, true));
    }

    if (resourceType === 'task' && !!resourceId) {
      this.store.dispatch(new CommentActions.LoadAllTaskComments(resourceId));
    }
  }

  public send(message: string, replyToId: string = null) {
    const comment = new Comment(null, CommentType.Comment, this._processId, null, message);
    comment.backtrackId = this._resourceId;
    comment.replyToId = replyToId;
    if (this._resourceType === 'artifact') {
      this.store.dispatch(new CommentActions.SendArtifactComment(this._resourceId, comment));
    } else if (this._resourceType === 'task') {
      this.store.dispatch(new CommentActions.SendTaskComment(this._resourceId, comment));
    } else {
      this.store.dispatch(new CommentActions.SendProcessComment(this._processId, comment));
    }
  }

  public saveComment(commentId: string, message: string) {
    if (this._resourceType === 'artifact') {
      this.store.dispatch(new CommentActions.SaveArtifactComment(this._resourceId, commentId, message));
    } else if (this._resourceType === 'task') {
      this.store.dispatch(new CommentActions.UpdateTaskComment(this._resourceId, commentId, message));
    } else {
      this.store.dispatch(new CommentActions.SaveComment(this._processId, commentId, message));
    }
  }

  public deleteComment(commentId: string) {
    if (this._resourceType === 'artifact') {
      this.store.dispatch(new CommentActions.DeleteArtifactComment(this._resourceId, commentId));
    } else if (this._resourceType === 'task') {
      this.store.dispatch(new CommentActions.DeleteTaskComment(this._resourceId, commentId));
    } else {
      this.store.dispatch(new CommentActions.DeleteComment(this._processId, commentId));
    }
  }

  public react(commentId: string, reactionType: CommentReactionType) {
    if (this._resourceType === 'artifact') {
      this.store.dispatch(new CommentActions.ReactOnArtifactComment(this._resourceId, commentId, reactionType));
    } else if (this._resourceType === 'task') {
      this.store.dispatch(new CommentActions.ReactOnTaskComment(this._resourceId, commentId, reactionType));
    } else {
      this.store.dispatch(new CommentActions.React(this._processId, commentId, reactionType));
    }
  }

  private init() {
    this.loading = this.store.select(CommentSelectors.getLoadingState);

    this.comments = combineLatest(this.processId$, this.resourceId$, this.resourceType$)
      .pipe(switchMap(([pid, rid, rType]) => this.getCommentsByResourceType(pid, rid, rType)));

    const pid$ = this.processId$.pipe(filter(pid => !!pid), distinctUntilChanged(), takeUntil(this.onDestroy));
    const rid$ = this.resourceId$.pipe(filter(rid => !!rid), distinctUntilChanged(), takeUntil(this.onDestroy));

    combineLatest(pid$, rid$, this.resourceType$)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(([pid, rid, rType]) => {
        this.fetchComments(pid, rid, rType)
      });
  }

  public refreshNode(pid, rid) {
    if (!pid || !rid) {
      return;
    }

    this.store.dispatch(new CollectorItemActions.LoadOne(pid, rid));
  }

  private getCommentsByResourceType(processId: string, resourceId: string, cType: CommentResourceType): Observable<Comment[]> {
    // Case Collecto and CAC. The reference is a Bom::Tree::Node.
    if (cType === 'node' && !!resourceId) {
      return this.store.select(CommentSelectors.getCommentByBacktrackId(resourceId));
    }

    // Case third party: All comments are process related but can have a reference to a third party line item.
    if (cType === 'process' && !!processId && !!resourceId) {
      return this.store.select(CommentSelectors.getCommentByBacktrackId(resourceId));
    }

    // Regular process comment: No referenced secondary resource.
    if (cType === 'process' && !!processId) {
      return this.store.select(CommentSelectors.getCommentsByProcessId(processId));
    }

    if (cType === 'artifact') {
      return this.store.select(CommentSelectors.getCommentByBacktrackId(resourceId));
    }

    if (cType === 'task') {
      return this.store.select(CommentSelectors.getCommentByBacktrackId(resourceId));
    }

    return of([]);
  }
}
