<!-- Flat tree structure -->
<mat-tree #tree
          [dataSource]="dataSource"
          [treeControl]="treeControl"
          [trackBy]="trackByFn"
          class="dvtx-process-tree dvtx-project-structure">
  <!-- Node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
    <div class="dvtx-leaf-node">
      <div class="dvtx-leaf-node-label">
        <fivef-process-tree-node [node]="node"
                                (onSelect)="selectNode(node)"
                                [noTitlePlaceholder]="noTitlePlaceholder"></fivef-process-tree-node>
      </div>
    </div>
  </mat-tree-node>

  <!-- Node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
    <div class="dvtx-expand-item d-flex align-items-center w-100">
      <div class="dvtx-expand-toggle">
        <a matTreeNodeToggle (click)="$event.preventDefault(); onToggleNodeExpanded(node)">
          <mat-icon>{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
        </a>
      </div>
      <div class="dvtx-inner-node">
        <div class="dvtx-inner-node-label">
          <fivef-process-tree-node matTreeNodeToggle [node]="node"
                                  (onSelect)="onToggleNodeExpanded(node); selectNode(node)"
                                  [noTitlePlaceholder]="noTitlePlaceholder"></fivef-process-tree-node>
        </div>
      </div>
    </div>
  </mat-tree-node>
</mat-tree>
